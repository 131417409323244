import React, { Component } from 'react'
// import { View, StyleSheet } from 'react-native'
// import Text from 'lib/ui/Text'
// import { Styles, Colors, UIValues } from '_app/Styles'

export default class NotFound extends Component {
  render() {
    return (
      <div>
        Not found
      </div>
      // <View style={styles.wrapper}>
      //   <View style={styles.innerWrapper}>
      //     <Text style={styles.text} text={'Hello world!'}/>
      //   </View>
      // </View>
    )
  }
}

// const styles = StyleSheet.create({
//   wrapper: {
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   innerWrapper: {
//     backgroundColor: 'green',
//   },
//   text: {
//     fontSize: 16,
//   },
// })
